import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AOS from "aos";
import Routes from "./routes/Routes";
import "./assets/scss/theme.scss";
import "react-phone-number-input/style.css";


const App: React.FC = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-XQGKE8BWEF"
          ></script>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=AW-10829164931"
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-XQGKE8BWEF');
          `}
          </script>

          <script>
            {`
              (function(c,l,a,r,i,t,y){
                c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "nbe1rt51py");
            `}
          </script>
        </Helmet>
      </HelmetProvider>
      <Routes />
    </>
  );
};

export default App;
